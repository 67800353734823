<template>
<!--eslint-disable-->
    <div>
        <v-dialog
            v-model="openmodal"
            persistent
            max-width="600px"
        >
            <v-card>
                <v-card-title>
                    <span class="fstitulomodal mb-0 bold">{{$t('solicitudes.Editarsolicitud')}}</span>
                    <v-spacer></v-spacer>
                    <v-btn
                       icon
                        @click="openmodal = false"
                    >
                       <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col
                                cols="6"
                                >
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    :return-value.sync="date"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="date"
                                        :label="$t('solicitudes.fecha_presunta')"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        outlined
                                        dense
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="date"
                                    no-title
                                    scrollable
                                    >
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                         color="black"
                                        @click="menu = false"
                                    >
                                        {{$t('solicitudes.cancelar')}}
                                    </v-btn>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.menu.save(date)"
                                    >
                                        OK
                                    </v-btn>
                                    </v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-select
                                :items="items"
                                label="region"
                                outlined
                                dense
                                ></v-select>
                            </v-col>
                            <v-col
                                cols="6"
                            >
                                <v-select
                                    v-model="value"
                                    :items="items1"
                                    :label="$t('solicitudes.servicio')"
                                    multiple
                                    outlined
                                    dense
                                    >
                                    <template v-slot:selection="{ item, index }">
                                        <v-chip v-if="index === 0">
                                        <span>{{ item }}</span>
                                        </v-chip>
                                        <span
                                        v-if="index === 1"
                                        >
                                        (+{{ value.length - 1 }})
                                        </span>
                                    </template>
                                    </v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-4 ma-2 c-btn c-btn--primary"
                        color="indigo darken-3"
                        @click="modalsave"
                    >
                        {{$t('solicitudes.')}}
                    </v-btn>
                    <v-btn
                        color="black"
                        class="ma-2 white--text"
                        @click="openmodal = false"
                    >
                        {{$t('solicitudes.cancelar')}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
         <!-- modal Editar -->
        <v-dialog v-model="dialogeditar" max-width="290">
            <v-card>
                <v-card-title class="text-h5 flex-direction-column" style="padding-bottom: 0px !important;">
                    <svg  width="60.575" height="60.576">
                    <use xlink:href='/img/icons/detalle-icons.svg#good'></use>
                    </svg>
                    <span class="txt-title-alert">{{$t('solicitudes.success')}}</span>
                </v-card-title>
                <v-card-text>
                    {{$t('solicitudes.Desearealizarloscambios')}}
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="mr-4 ma-2 c-btn c-btn--primary"
                        color="indigo darken-3"
                        @click="dialogeditar = false"
                    >
                        {{$t('analisis.aceptar')}}
                    </v-btn>
                     <v-btn
                        color="black"
                        class="ma-2 white--text"
                        @click="dialogeditar = false"
                    >
                        {{$t('solicitudes.cancelar')}}
                    </v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
/*eslint-disable*/
export default {
    data: () => ({
        dialogeditar: false,
        openmodal: false,
        date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        menu: false,
        modal: false,
        items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
        items1: ['Retiro', 'Muestreo', 'Muestreo Laboratorio'],
        value: [],
    }),
    methods: {
        showmodal(){
            this.openmodal = true;
        },
        modalsave(){
            this.openmodal = false;
            this.dialogeditar = true;
        },
    },       
}
</script>

